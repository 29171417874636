<!-- eslint-disable @shein-aidc/cccx/notUseImgTag -->
<template>
  <div 
    v-if="shopData"
    :class="['banner-wrap', {'fashion-card': !!fashionBg}]"
  >
    <div 
      v-expose="{
        id: '2-27-10',
        code: exposeCode,
        delayReady: 1500,
        data: {
          store_code: shopData.storeCode,
          store_banner: shopData.bannerImg ? 1 : 0
        }
      }"
      class="search-shop-banner"
      @click="handleStoreEntryClick"
    >
      <header class="search-shop-banner__header header">
        <div class="header__left">
          <BaseImg
            class="header__logo"
            :first-screen="true"
            :is-support-webp="true"
            :img-design-width="40"
            :img-src="shopData.logo"
          />
          <div class="header__content">
            <div class="header__title">
              {{ shopData.title }}
            </div>
            <div class="header__labels-container">
              <div
                v-for="(item, index) in shopData.storeQuality"
                :key="index"
                class="header__labels"
              >
                <!-- 时尚标签 -->
                <div 
                  v-if="item.type == 'trends'"
                  class="header__label-image"
                >
                  <img
                    v-if="shopData?.trendsLabelInfo?.trendsLabelImg"
                    :src="shopData?.trendsLabelInfo?.trendsLabelImg"
                  />
                  <div 
                    v-else
                    class="header__label-fallback"
                  >
                    <span>{{ shopData?.trendsLabelInfo?.trendsLabelText || '' }}</span>
                    <img
                      src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' fill='none' viewBox='0 0 5 14'%3E%3Cpath id='Rectangle 34624868' fill='%239462FF' d='M3.424 11.03 5 0H0v14a3.46 3.46 0 0 0 3.424-2.97Z'/%3E%3C/svg%3E"
                      class="trapezoid-icon"
                      :class="{ 'trapezoid-icon_ar': constantData.GB_cssRight }"
                    />
                  </div>
                </div>
                <!-- 品质标签 -->
                <div 
                  v-if="item.type == 'choices'"
                  class="header__label-image"
                >
                  <img
                    :src="item.icon"
                  />
                </div>
                <!-- 品牌店铺 -->
                <div
                  v-if="item.type == 'brand'"
                  class="header__store_brand header__label"
                >
                  <BaseImg
                    class="header__label_icon"
                    :first-screen="true"
                    :is-support-webp="true"
                    :img-design-width="12"
                    :img-src="`${locals.PUBLIC_CDN}/pwa_dist/images/ccc/branchBannner-icon-52850ee628.svg`"
                  />
                  <div class="header__label_txt">
                    {{ language.SHEIN_KEY_PWA_24319 }}
                  </div>
                </div>
              
                <!-- 优质店铺 -->
                <div
                  v-if="item.type == 'preferred'"
                  class="header__store_best header__label"
                >
                  <BaseImg
                    class="header__label_icon"
                    :first-screen="true"
                    :is-support-webp="true"
                    :img-design-width="12"
                    :img-src="`${locals.PUBLIC_CDN}/pwa_dist/images/store/store_preferred_seller-bb758e2772.png`"
                  />
                  <div class="header__label_txt header__color_best ">
                    {{ language.SHEIN_KEY_PWA_20758 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header__right">
          <div>{{ language.SHEIN_KEY_PWA_20627 }}</div>
          <i class="suiiconfont sui_icon_more_right_12px"></i>
        </div>
      </header>
      <section 
        v-if="showShopCenterInfo" 
        class="search-shop-banner__content store-data"
      >
        <div
          v-if="shopData.score && shopData.score !== '0'"
          class="store-data__line"
        >
          <div class="store-data__key">
            {{ language.SHEIN_KEY_PWA_20750 }}
          </div>
          <div class="store-data__mark">
            :
          </div>
          <div class="store-data__value">
            {{ formatShopScore }}
          </div>
        </div>
        <div
          v-if="shopData.followsCount && shopData.followsCount !== '0'"
          class="store-data__line"
        >
          <div class="store-data__key">
            {{ language.SHEIN_KEY_PWA_24069 }}
          </div>
          <div class="store-data__mark">
            :
          </div>
          <div class="store-data__value">
            {{ shopData.followsCount }}
          </div>
        </div>
        <div
          v-if="shopData.storeTags?.length"
          class="store-data__hot-labels"
        >
          <div 
            v-if="(shopData.score && shopData.score !== '0') || (shopData.followsCount && shopData.followsCount !== '0')" 
            class="store-data__divider-line"
          ></div>
          <StoreLabelSwiper :labelList="shopData.storeTags" />
        </div>
      </section>
      <section 
        v-if="shopData.bannerImg"
        class="search-shop-banner__banner"
      >
        <section
          class="search-shop-banner__banner-img"
          :style="'background-image: url('+ shopData.bannerImg +');'"
        >
        </section>
      </section>
      <section
        v-if="shopData.products.length > 3"
        class="goods-list"
      >
        <div 
          v-for="(item, index) in shopData.products"
          :key="index"
        >
          <div 
            class="goods-list__item"
            @click.stop="handleStoreDetailClick(item)"
          >
            <!-- <BaseImg
              class="goods-list__img"
              :first-screen="true"
              :is-support-webp="true"
              fit="cover"
              position="center"
              :img-design-width="80"
              :img-src="item.goods_img"
            /> -->
            <img
              class="goods-list__img"
              :src="item.goods_img"
            />
            
            <div class="goods-list__price">
              <ProductCardPriceSimple 
                :goods-info="item"
                :language="language"
                :isPaid="!!sheinClubInfo?.isPaid" 
                :config="{
                  hidePriceBottomLabel: true,
                  noCamelCasePrice: true,
                  priceColor: shopData.priceColor
                }"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import { parseQueryString, stringifyQueryString } from '@shein/common-function'
import ProductCardPriceSimple from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/ProductCardPriceSimple.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '2-27' })
export default {
  name: 'SearchShopBanner',
  components: {
    BaseImg,
    ProductCardPriceSimple,
    StoreLabelSwiper: () => import('public/src/pages/components/ccc/components/shop-banner/StoreLabelSwiper.vue')
  },
  props: {
    shopData: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    sheinClubInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    locals: {
      type: Object,
      default() {
        return {}
      }
    },
    exposeCode: {
      type: String,
      default: ''
    },
    fashionBg: {
      type: String,
      default: ''
    },
    constantData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    formatShopScore() {
      return (+this.shopData.score).toFixed(2)
    },
    showShopCenterInfo () {
      const { score, followsCount, storeTags } = this.shopData
      return !!parseFloat(score) || !!parseInt(followsCount) || !!storeTags.length
    },
  },
  methods: {
    // 跳转到店铺主页
    handleStoreEntryClick({ target }) {
      daEventCenter.triggerNotice({
        daId: '2-27-11',
        extraData: {
          store_code: this.shopData.storeCode,
          store_banner: target.className.includes('banner-img') ? 1 : 0
        },
      })
      const { src_module = '', src_identifier, src_tab_page_id = '' } = parseQueryString(location.search)
      const srcData = {
        src_module,
        src_identifier,
        src_tab_page_id,
        page_from: 'PageSearchResult',
        rule_poskey: 'SearchshopitemList'
      }
      const { langPath = '' } = gbCommonInfo || {}
      const url = `${langPath}/store/home?store_code=${
        this.shopData.storeCode
      }&${stringifyQueryString({ queryObj: srcData })}`
      this.$router.push(url)
    },
    // 跳转到店铺主页并置顶商品
    async handleStoreDetailClick(product = {}) {
      const { storeCode } = this.shopData
      const { goods_id, goods_sn, productRelationID, salePrice, retailPrice, mall_code, cat_id } = product
      const goodsId = goods_id
      const skuId = goods_sn
      const spuId = productRelationID
      const page = 1
      const index = 0
      const operation = 1
      const recMark = ''
      const price = salePrice?.usdAmount
      const originPrice = retailPrice?.usdAmount
      const  goods_to_list = `${goodsId}\`${skuId}\`${spuId}\`${+index + 1}\`${page}\`${operation}\`${recMark}\`\`pri_${price}|estimated_pri_${originPrice}\`\`\`mall_${mall_code}` // goods_list: goods_id`sku_id`spu_id`坑位`页码`运营位置`流量标识rec_mark
      daEventCenter.triggerNotice({
        daId: '2-27-11',
        extraData: {
          goods_to_list,
          store_code: storeCode
        },
      })
      
      const { langPath = '' } = gbCommonInfo || {}
      const {  src_module = '', src_identifier, src_tab_page_id = '' } = parseQueryString(location.search)
      const srcData = {
        store_code: storeCode,
        adp: goods_id,
        page_from: 'PageSearchResult',
        src_tab_page_id,
        src_module,
        src_identifier,
        main_goods_id: goods_id,
        main_cate_id: cat_id,
        rule_poskey: 'SearchshopitemList',
        ...(goods_id ? { isStoreTabSticky: 1, tabId: 'items' } : null)
      }
      const url = `${langPath}/store/home?${stringifyQueryString({ queryObj: srcData })}`
      this.$router.push(url)
    }
  }
}
</script>

<style lang="less" scoped>
// 设计稿用的 375px
@unit: 375/100vw;
.banner-wrap {
  padding: 12/ @unit 8 / @unit;
  background-color: var(---gray_weak2, #F6F6F6);
  &.fashion-card {
    padding-top: 8 / @unit;
    padding-bottom: 4 / @unit;
    position: relative;
    z-index: 1;
    background-color: transparent;
    .search-shop-banner {
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.60);
      backdrop-filter: blur(4px);
      border: 1px solid rgba(151, 128, 199, 0.10);
    }
    .store-data__divider-line {
      background-color: #959595;
    }
  }
}
.search-shop-banner {
  padding: 8 / @unit;
  border-radius: 8 / @unit;
  background: #FFF;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__content {
    margin-top: 8 / @unit;
    display: flex;
    align-items: center;
  }
  &__banner {
      margin-top: 8 / @unit;
      height: 100 / @unit;
      &-img {
        height: 100%;
        width: 100%;
        border-radius: 4 / @unit;
        background-repeat: no-repeat;
        background-size: cover;
      }
  }
}
.header {
  &__left {
    display: flex;
    align-items: center;
    height: 40 / @unit;
  }
  &__right {
    border-radius: 4 / @unit;
  }
  &__logo {
    width: 40 / @unit;
    height: 40 / @unit;
    border-radius: 4 / @unit;
    .border-dpr(border, 1 / @unit, #E5E5E5);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    background-color: #FFFFFF;
    > img {
      max-height: 100%;
      height: auto;
    }
  }
  &__content {
    height: 40 / @unit;
    margin-left: 8 / @unit;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__title {
    color: #222;
    font-size: 14 / @unit;
    font-weight: 700;
    line-height: 17 / @unit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 180 / @unit;
  }
  &__labels-container {
    display: flex;
    align-items: center;
    margin-top: 4 / @unit;
    flex-wrap: wrap;
    height: 16 / @unit;
    overflow: hidden;
    margin-right: 8 / @unit;
  }
  &__labels {
    display: flex;
    align-items: center;
    margin-right: 4 / @unit;
  }
  &__label {
    height: 16 / @unit;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-right: 2 / @unit;
  }
  &__store_brand {
    display: flex;
    padding: 0 4 / @unit 0 2 /@unit;
    align-items: center;
    border-radius: 2 / @unit;
    border: 0.5px solid #BCC8D3;
    background: linear-gradient(129deg, #E2EDF3 7.72%, #F0F8FF 34.23%, #DAE7F6 98.52%);
  }
  &__store_best {
    display: flex;
    padding: 0 4 / @unit 0 2 /@unit;
    align-items: center;
    border-radius: 2 / @unit;
    background: #FFF8EB;
    color: #A86104;
  }
  &__label-image {
    height: 16 / @unit;
    display: flex;
    img {
      height: 100%;
    }
  }
  &__label-fallback {
    height: 16 / @unit;
    background-color: #9462ff;
    color: #fff;
    position: relative;
    border-top-left-radius: 2 / @unit;
    border-bottom-left-radius: 2 / @unit;
    padding-left: 4 / @unit;
    height: 16 / @unit;
    .trapezoid-icon {
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(100%);
      font-size: 0;
      width: fit-content;
      &_ar {
        transform: translateX(100%) rotateY(180deg);
      }
    }
  }
  &__label_icon {
    width: 12 / @unit;
    margin-right: 2 / @unit;
  }
  &__label_txt {
    height: 16 / @unit;
    font-size: 12 / @unit;
    line-height: 16 / @unit;
  }
  &__store_normal {
    display: flex;
    height: 100%;
    align-items: center;
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5 / @unit 8 / @unit;
    background: var(---sui_color_gray_dark1, #000);
    color: var(---sui_color_white, #FFF);
    font-size: 12 / @unit;
  }
}
.store-data {
  &__line {
    display: flex;
    align-items: center;
    padding-right: 8 / @unit;
  }
  &__key {
    font-size: 11 / @unit;
    color: @sui_color_gray_dark2;
  }
  &__mark {
    color: #222;
    font-size: 11 / @unit;
    margin-right: 4 / @unit;
  }
  &__value {
    color: #222;
    font-size: 12 / @unit;
    font-weight: 500;
  }
  &__hot-labels{
    display: flex;
    padding-left: 8 / @unit;
    position: relative;
  }
  &__divider-line {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1 / @unit;
    height: 10 / @unit;
    display: inline-block;
    background: #E5E5E5;
  }
}
.goods-list {
  margin-top: 8 / @unit;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8 / @unit;
  &__item {
    position: relative;
  }
  &__img {
    width: 80 / @unit;
    height: 80 / @unit;
    border-radius: 4 / @unit;
    box-shadow: 0 0 1 / @unit 0 rgba(0, 0, 0, 0.2);
    object-fit: cover;
    object-position: center;
  }
  &__price {
    padding: 1 / @unit 3 / @unit;
    position: absolute;
    bottom: 4 / @unit;
    right: 4 / @unit;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2 / @unit;
    background: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(1 / @unit);
    box-shadow: 0 0 2 / @unit 0 rgba(0, 0, 0, 0.12);
  }
  /deep/ .product-card__price-simple .price-content{
    padding-right: 0;
  }
}

</style>
