import { hypernymGoodsApiCache, cccFeedbackApiCache } from 'public/src/pre_requests/modules/productList/api/bffApi.js'
import { getViewedGoods } from 'public/src/pre_requests/modules/productList/bffParamsHandle/common.js'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'

const isInListSearch = (query) => { // 是否是列表页内搜索
  return !(query?.sil_without_entry || !query?.sil_type)
}
const getSearchPoskey = (query) => {
  return !isInListSearch(query) ? 'SearchPageSort' : 'ListSearchSort'
}

export const bffHypernymGoods = async ({ 
  toRoute, 
  query, 
  catInfo, 
  language,
  goods,
  lang,
  sheinClubInfo
}) => {
  const { keywords, page, limit } = query
  const hypernymGoodsParams = {
    keywords,
    limit,
    page,
    search_goods: getViewedGoods(toRoute, {
      type: 'nextpage',
      query: { keywords, limit, page }
    }, goods),
    list_cat_id: isInListSearch(catInfo) ? catInfo.list_cat_id :  '',  // 商品列表分类ID,
    poskey: getSearchPoskey(catInfo)
  }

  const [productReq, cccFeedbackReq] = await Promise.all([hypernymGoodsApiCache.request({ params: hypernymGoodsParams }), cccFeedbackApiCache.request({ v: '1.0' })])
  const cccFeedback = cccFeedbackReq?.info?.extendConfigDetail?.find((item) => {
    return item?.pageStyleKey === 'page_search' && item.sceneKey === 'TWO_IN_A_ROW'
  })
  const { products, request_ext,  buriedPoint, num, empty_search_multi_lang, not_empty_search_multi_lang, suggestedSaleType }  = productReq.info
  const hypernymProduct = {
    sum: num || 0,
    // keywords,
    page,
    limit,
    noMoreData: (products || []).length < limit,
    emptyLang: empty_search_multi_lang,
    noEmptyLang: not_empty_search_multi_lang,
  }
  const transProducts = products?.map?.(v => {
    v.cccFeedback = cccFeedback
    const goodsInfo = goodsTransformation(v, {
      suggestedSaleType: suggestedSaleType || '',
      language,
      lang,
      isPaid: !!sheinClubInfo?.isPaid
    })
    goodsInfo.isHypernymGoods = true
    return goodsInfo
  })

  return { hypernymProduct, request_ext, buriedPoint, products: transProducts || []}
}
